/* eslint-disable no-console */
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FingoLogo } from '../assets/images';
import { acceptedTermsVar } from '../graphql/reactive-variables';
import { Button } from './Layout';
import useEnroll from '../hooks/useEnroll';

const EnrollmentForm = () => {
  const [enroll, { disabled, loading, error, errorMessage, rut, password,
    setPassword }] = useEnroll();
  const checked = useReactiveVar(acceptedTermsVar);
  console.log('EnrollmentForm rendered');
  return (
    <Stack alignItems="center" justifyContent="start" spacing="28px" height="100%" px={5} mb="auto">
      <Typography
        color="primary"
        fontWeight="bold"
        variant="caption"
        height="12px"
      >
        {errorMessage}
      </Typography>
      <TextField
        id="rut"
        name="rut"
        label="RUT"
        variant="filled"
        value={rut}
        disabled
        mt="0 !important"
      />
      <Stack spacing={0} mt="35px !important">
        <TextField
          id="password"
          name="password"
          type="password"
          label="Clave SII"
          variant="filled"
          placeholder="••••••••"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          error={error}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && checked && !disabled && !loading) {
              enroll();
            }
          }}
          required
        />
      </Stack>
      <Button
        id="upload-credentials"
        type="submit"
        disabled={disabled || !checked}
        onClick={enroll}
        label="Registrar"
        loading={loading}
        sx={{ mt: '20px !important',
          minHeight: { xs: '48px', desktop: '36px' },
          width: { xs: '100%', desktop: '220px' },
          fontSize: { xs: '16px', desktop: '14px' },
        }}
      />
      <Typography variant="caption" textAlign="center">
        En alianza con
        <img
          src={FingoLogo}
          alt="fingo-enrollment-logo"
          style={{ width: '50px', height: 'auto', verticalAlign: 'middle', margin: '0 0 3px 4px' }}
        />
      </Typography>
    </Stack>
  );
};

export default EnrollmentForm;
